import React from "react";
import styles from "./aboutMe.module.scss";
import classnames from "classnames";

import self from "assets/self.png";

const AboutMe = (props) => {
  const skillInfo = [
    {
      title: "React",
      val: "4 Application"
    },
    {
      title: "Android",
      val: "10 Application"
    },
    {
      title: "Ionic",
      val: "3 Application"
    },
    {
      title: "React Native",
      val: "2 Application"
    }
  ];

  const educationInfo = [
    {
      title: "BE (EXTC)",
      val: "2019"
    },
    {
      title: "Diploma (Electronics)",
      val: "2016"
    }
  ];

  return (
    <div className={classnames(styles.container)}>
      <div
        className={classnames(styles.homeBtn)}
        onClick={() => {
          console.log("section1 = ", props.section1);
          props.section1.current.scrollIntoView();
        }}>
        home
      </div>
      <div className={classnames(styles.imgContainer)}>
        <img
          onContextMenu={(e) => {
            e.preventDefault();
          }}
          onMouseDown={(e) => {
            e.preventDefault();
          }}
          className={classnames(styles.self)}
          src={self}
          alt="Hello"
        />
      </div>

      <div className={classnames(styles.infoContainer)}>
        <div className={classnames(styles.leftParent)}>
          <div className={classnames(styles.infoWrapper)}>
            <div className={classnames(styles.infoTitle)}>Few Skills in my Arsenal</div>
            <div className={classnames(styles.subParent)}>
              <div className={classnames(styles.infoSubTitle)}>
                This is my favourite set of tools. I have developed few projects using following frameworks.
              </div>
              <div className={classnames(styles.infoBlockWrapper)}>
                {skillInfo.map((item, i) => {
                  return (
                    <div className={classnames(styles.infoBlock)} key={i}>
                      <div className={classnames(styles.infoBlockTitle)}>{item.title}</div>
                      <div className={classnames(styles.infoBlockValue)}>{item.val}</div>
                    </div>
                  );
                })}
              </div>
            </div>
          </div>

          <div className={classnames(styles.infoWrapper)}>
            <div className={classnames(styles.infoTitle)}>Education</div>
            <div className={classnames(styles.subParent)}>
              <div className={classnames(styles.infoSubTitle)}>
                I Have pursued the field of Applied Science i.e. Engineering.
              </div>
              <div className={classnames(styles.infoBlockWrapper)}>
                {educationInfo.map((item, i) => {
                  return (
                    <div className={classnames(styles.infoBlock)} key={i}>
                      <div className={classnames(styles.infoBlockTitle)}>{item.title}</div>
                      <div className={classnames(styles.infoBlockValue)}>{item.val}</div>
                    </div>
                  );
                })}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AboutMe;
