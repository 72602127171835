import React from "react";
import styles from "./myApps.module.scss";
import classnames from "classnames";

import mobile1 from "assets/images/mobile1.png";
import mobile2 from "assets/images/mobile2.png";
import mobile3 from "assets/images/mobile3.png";
import mobile4 from "assets/images/mobile4.png";
import mobile5 from "assets/images/mobile5.png";

import m1_a1 from "assets/images/m1_a1.svg";
import m1_a2 from "assets/images/m1_a2.svg";
import m1_a3 from "assets/images/m1_a3.svg";

import m2_a1 from "assets/images/m2_a1.svg";
import m2_a2 from "assets/images/m2_a2.svg";
import m2_a3 from "assets/images/m2_a3.svg";
import m2_a4 from "assets/images/m2_a4.svg";
import m2_a5 from "assets/images/m2_a5.svg";

import m3_a1 from "assets/images/m3_a1.svg";
import m3_a2 from "assets/images/m3_a2.svg";
import m3_a3 from "assets/images/m3_a3.svg";
import m3_a4 from "assets/images/m3_a4.svg";

import m4_a1 from "assets/images/m4_a1.svg";
import m4_a2 from "assets/images/m4_a2.svg";
import m4_a3 from "assets/images/m4_a3.svg";
import m4_a4 from "assets/images/m4_a4.svg";

import m5_a1 from "assets/images/m5_a1.svg";
import m5_a2 from "assets/images/m5_a2.svg";
import m5_a3 from "assets/images/m5_a3.svg";
import m5_a4 from "assets/images/m5_a4.svg";

const MyApps = (props) => {
  const appInfo = [
    {
      title: "Practosophy: Aptitude",
      subTitle: ["Native Android (java & xml)"],
      val: "2015"
    },
    {
      title: "Scoreboard",
      subTitle: ["Hybrid Ionic (React)", "Firebase: firestore & cloud messaging"],
      val: "2021"
    },
    {
      title: "Fantasy Adda",
      subTitle: ["Hybrid Ionic (React)", "Firebase: firestore & cloud messaging"],
      val: "2018"
    },
    {
      title: "Kwikid",
      subTitle: ["Hybrid Ionic (Angular, redux)", "Flask (python), Aws (dynamo DB, s3, ec2)"],
      val: "2020"
    }
  ];

  const m1_detail = [
    { arrow: m1_a1, position: "right" },
    { arrow: m1_a2, position: "right" },
    { arrow: m2_a5, position: "right" }
  ];

  const m2_detail = [
    { arrow: m2_a1, position: "left" },
    { arrow: m2_a2, position: "left" },
    { arrow: m2_a3, position: "right" },
    { arrow: m2_a4, position: "right" },
    { arrow: m2_a5, position: "right" }
  ];

  const m3_detail = [
    { arrow: m3_a4, position: "left" },
    { arrow: m3_a1, position: "left" },
    { arrow: m3_a2, position: "right" },
    { arrow: m3_a3, position: "right" }
  ];

  const m4_detail = [
    { arrow: m4_a1, position: "left" },
    { arrow: m4_a2, position: "left" },
    { arrow: m4_a3, position: "left" },
    { arrow: m4_a4, position: "left" }
  ];

  const m5_detail = [
    { arrow: m5_a1, position: "left" },
    { arrow: m5_a2, position: "left" },
    { arrow: m5_a3, position: "left" },
    { arrow: m5_a4, position: "left" }
  ];

  return (
    <div className={classnames(styles.container)}>
      <div
        className={classnames(styles.homeBtn)}
        onClick={() => {
          console.log("section1 = ", props.section1);
          props.section1.current.scrollIntoView();
        }}>
        home
      </div>

      <div className={classnames(styles.infoContainer)}>
        <div className={classnames(styles.leftParent)}>
          <div className={classnames(styles.infoWrapper)}>
            <div className={classnames(styles.infoTitle)}>My Work</div>
            <div className={classnames(styles.subParent)}>
              <div className={classnames(styles.infoSubTitle)}>
                This are few application and designs which I have worked upon over the years of my learning.
              </div>
              <div className={classnames(styles.infoBlockWrapper)}>
                {appInfo.map((item, i) => {
                  return (
                    <div className={classnames(styles.infoBlock)} key={i}>
                      <div className={classnames(styles.infoBlockTitle)}>{item.title}</div>
                      {item.subTitle &&
                        item.subTitle.map((innerItem, i) => {
                          return (
                            <div className={classnames(styles.infoBlockValue)} key={i}>
                              {innerItem}
                            </div>
                          );
                        })}
                      <div className={classnames(styles.infoBlockValue)}>{item.val}</div>
                    </div>
                  );
                })}
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className={classnames(styles.mobileParent)}>
        <div className={classnames(styles.mobileCenterParent1)}>
          <div className={classnames(styles.m1Wrapper)}>
            <img className={classnames(styles.mobile, styles.mobile1)} src={mobile1} alt="app design" />
            <div className={classnames(styles.detailParent)}>
              {m1_detail.map((item, i) => {
                return (
                  <div key={i} style={{ backgroundImage: `url(${item.arrow})` }} className={classnames(styles.detail)}>
                    {}
                  </div>
                );
              })}
            </div>
          </div>
        </div>
        <div className={classnames(styles.mobileCenterParent2)}>
          <div className={classnames(styles.m2Wrapper)}>
            <img className={classnames(styles.mobile, styles.mobile2)} src={mobile2} alt="app design" />
            <div className={classnames(styles.detailParent)}>
              {m2_detail.map((item, i) => {
                return (
                  <div
                    key={i}
                    style={{ backgroundImage: `url(${item.arrow})` }}
                    className={classnames(styles.detail, { [styles.left]: item.position === "left" })}>
                    {}
                  </div>
                );
              })}
            </div>
          </div>
          <div className={classnames(styles.m3Wrapper)}>
            <img className={classnames(styles.mobile, styles.mobile3)} src={mobile3} alt="app design" />
            <div className={classnames(styles.detailParent)}>
              {m3_detail.map((item, i) => {
                return (
                  <div
                    key={i}
                    style={{ backgroundImage: `url(${item.arrow})` }}
                    className={classnames(styles.detail, { [styles.left]: item.position === "left" })}>
                    {}
                  </div>
                );
              })}
            </div>
          </div>
        </div>
        <div className={classnames(styles.mobileCenterParent3)}>
          <div className={classnames(styles.m4Wrapper)}>
            <img className={classnames(styles.mobile, styles.mobile4)} src={mobile4} alt="app design" />
            <div className={classnames(styles.detailParent)}>
              {m4_detail.map((item, i) => {
                return (
                  <div
                    key={i}
                    style={{ backgroundImage: `url(${item.arrow})` }}
                    className={classnames(styles.detail, { [styles.left]: item.position === "left" })}>
                    {}
                  </div>
                );
              })}
            </div>
          </div>
          <div className={classnames(styles.m5Wrapper)}>
            <img className={classnames(styles.mobile, styles.mobile5)} src={mobile5} alt="app design" />
            <div className={classnames(styles.detailParent)}>
              {m5_detail.map((item, i) => {
                return (
                  <div
                    key={i}
                    style={{ backgroundImage: `url(${item.arrow})` }}
                    className={classnames(styles.detail, { [styles.left]: item.position === "left" })}>
                    {}
                  </div>
                );
              })}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default MyApps;
