import React, { useState, useEffect } from "react";
import styles from "./home.module.scss";
import classnames from "classnames";

const Home = (props) => {
  const desktopList = [
    {
      info: [
        { text: "I'm" },
        {
          text: " Shivam Pandey",
          pop: {
            text: "About me",
            class: classnames(styles.highlightText),
            popClass: styles.pop1,
            onClick: () => {
              props.section2.current.scrollIntoView();
            }
          }
        },
        { text: ", a designer and developer." }
      ]
    },
    {
      info: [
        { text: "Over the years I have developed few" },
        {
          text: "apps",
          pop: {
            text: "My apps",
            class: classnames(styles.highlightText, styles.highlight2),
            popClass: styles.pop2,
            onClick: () => {
              props.section3.current.scrollIntoView();
            }
          }
        },
        { text: "," }
      ]
    },
    {
      info: [
        { text: "Worked on" },
        {
          text: "UI/UX",
          pop: {
            text: "Visit dribbble",
            class: classnames(styles.highlightText, styles.highlight2),
            popClass: styles.pop3,
            onClick: () => {
              props.section3.current.scrollIntoView();
            }
          }
        },
        { text: " and few" },
        {
          text: "Robots",
          pop: {
            text: "Explore",
            class: classnames(styles.highlightText, styles.highlight2),
            popClass: styles.pop4,
            onClick: () => {
              props.section3.current.scrollIntoView();
            }
          }
        },
        { text: "." }
      ]
    }
  ];
  const moblieList = [
    {
      info: [
        { text: "I'm" },
        {
          text: "Shivam Pandey",
          pop: {
            text: "About me",
            class: classnames(styles.highlightText),
            popClass: styles.pop1,
            onClick: () => {
              props.section2.current.scrollIntoView();
            }
          }
        },
        { text: ", a designer" }
      ]
    },
    {
      info: [
        {
          text: " and developer. Over the"
        }
      ]
    },
    {
      info: [{ text: "years I have developed few" }]
    },
    {
      info: [
        {
          text: "apps",
          pop: {
            text: "My apps",
            class: classnames(styles.highlightText, styles.highlight2),
            popClass: styles.pop2,
            onClick: () => {
              props.section3.current.scrollIntoView();
            }
          }
        },
        { text: ", Worked on" },
        {
          text: "UI/UX",
          pop: {
            text: "Visit dribbble",
            class: classnames(styles.highlightText, styles.highlight2),
            popClass: styles.pop3,
            onClick: () => {
              props.section3.current.scrollIntoView();
            }
          }
        }
      ]
    },
    {
      info: [
        { text: " and few" },
        {
          text: "Robots",
          pop: {
            text: "Explore",
            class: classnames(styles.highlightText, styles.highlight2),
            popClass: styles.pop4,
            onClick: () => {
              props.section3.current.scrollIntoView();
            }
          }
        },
        { text: "." }
      ]
    }
  ];

  const [showPop, setShowPop] = useState(true);
  const [detailRow, setDetailRow] = useState(desktopList);

  useEffect(() => {
    if (props.isMobile) {
      setDetailRow(moblieList);
      // setShowPop(false);
    } else {
      setDetailRow(desktopList);
      // setShowPop(true);
      //
    }
  }, [props.isMobile]);

  return (
    <div className={classnames(styles.textParent)}>
      {detailRow.map((item, i) => {
        return (
          <div key={i}>
            {item.info.map((innerItem, ii) => {
              return (
                <a key={ii}>
                  {innerItem.pop ? (
                    <span className={innerItem.pop.class} onClick={innerItem.pop.onClick}>
                      {showPop && (
                        <div className={classnames(styles.popWrap, innerItem.pop.popClass)}>
                          <div className={classnames(styles.textPop)}>{innerItem.pop.text}</div>
                        </div>
                      )}
                      {innerItem.text}
                    </span>
                  ) : (
                    <>{innerItem.text}</>
                  )}
                </a>
              );
            })}
          </div>
        );
      })}
    </div>
  );
};

export default Home;
