import "./App.css";
import React, { useState } from "react";
import styles from "./app.module.scss";
import classnames from "classnames";
import Home from "components/Home";
import AboutMe from "components/AboutMe";
import MyApps from "components/MyApps";
import { useMediaQuery } from "react-responsive";

function App() {
  const section1 = React.createRef();
  const section2 = React.createRef();
  const section3 = React.createRef();

  return (
    <div className={classnames(styles.sectionParent)}>
      <section ref={section1} className={classnames(styles.sectionBlock)}>
        <Home section2={section2} section3={section3} isMobile={useMediaQuery({ maxDeviceWidth: 767 })} />
      </section>
      <section ref={section2} className={classnames(styles.sectionBlock2)}>
        <AboutMe section1={section1} />
      </section>
      <section ref={section3} className={classnames(styles.sectionBlock3)}>
        <MyApps section1={section1} />
      </section>
    </div>
  );
}

export default App;
